import React, { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import InitiativeForm from "components/InitiativeForm";
import MainLayout from "components/MainLayout";

import useOrganizations from "utils/useOrganizations";
import "./style.less";

import {
  updateInitiative,
  rejectProposal,
  cancelInitiative,
} from "actions/api";

const DEFAULT_INITIATIVE_TYPE = "volunteering";

const EditInitiative = ({ intl, loading, error, initiative, history }) => {
  const dispatch = useDispatch();
  const push = history.push;

  const { organization } = useOrganizations();

  const { data: profile } = useSelector((state) => state.profile);

  const taxonomies = useSelector((state) => state.taxonomies);

  const communityConfig = useMemo(() => {
    if (!profile) {
      return {};
    }
    return profile.organization.product_config.community;
  }, [profile]);

  const type = (initiative && initiative.type) || DEFAULT_INITIATIVE_TYPE;
  const subtype = (initiative && initiative.subtype) || null;
  const t = intl.messages;

  const onUpdateInitiative = useCallback(
    (body) => {
      dispatch(updateInitiative(organization.slug, initiative.slug, body));
    },
    [dispatch, initiative.slug, organization.slug],
  );

  const onRejectProposal = useCallback(
    (reason) => {
      dispatch(rejectProposal(organization.slug, initiative.slug, reason));
    },
    [dispatch, organization.slug, initiative.slug],
  );

  const onCancelInitiative = useCallback(
    (organizationSlug, initiativeSlug, cancelReason, status) => {
      dispatch(
        cancelInitiative(
          organizationSlug,
          initiativeSlug,
          cancelReason,
          null,
          status,
        ),
      );
    },
    [dispatch],
  );

  const onDeleteDraftInitiative = useCallback(() => {
    push("/initiative");
    dispatch(cancelInitiative(organization.slug, initiative.slug, null, true));
  }, [dispatch, initiative.slug, organization.slug, push]);

  return (
    <MainLayout
      errorMessage={intl.formatMessage({
        id: "error_boundary_edit_initiative_message",
      })}
    >
      {initiative.status === "proposed" ? (
        <Link to="/proposal">
          <section className="backToActions">
            <img src="/images/chevron-left.svg" alt={t.back_to_proposals} />
            <span>{t.back_to_proposals}</span>
          </section>
        </Link>
      ) : (
        <Link to="/initiative">
          <section className="backToActions">
            <img src="/images/chevron-left.svg" alt={t.back_to_actions} />
            <span>{t.back_to_actions}</span>
          </section>
        </Link>
      )}
      <InitiativeForm
        organization={organization}
        loading={loading}
        error={error}
        type={type}
        subtype={subtype}
        taxonomies={taxonomies}
        defaultValues={initiative}
        onSubmit={onUpdateInitiative}
        onRejectProposal={onRejectProposal}
        cancelInitiative={onCancelInitiative}
        onDeleteDraftInitiative={
          initiative.status === "draft" ? onDeleteDraftInitiative : null
        }
        hideCategories={communityConfig.hide_categories}
        hideTargetGroups={communityConfig.hide_target_groups}
        formMode={initiative.status === "draft" ? "draft" : null}
      />
    </MainLayout>
  );
};

export default injectIntl(withRouter(EditInitiative));
