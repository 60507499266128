import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { injectIntl } from "react-intl";

import { formatDateLong, isAfterNow } from "utils/date";
import { parseScore } from "utils/score";

import { Button, Modal, Table, Row, Col, Rate } from "antd";
import { UserOutlined } from "@ant-design/icons";

import Avatar from "components/Avatar";
import useOrganizations from "utils/useOrganizations";

import "./style.less";

import { acceptCollaborator, rejectCollaborator } from "actions/api";

const InitiativeCollaborators = ({ intl, ...initiative }) => {
  const t = intl.messages;
  const dispatch = useDispatch();

  const { organization } = useOrganizations();
  const { config: organizationConfig } = organization;

  const showRejectConfirm = useCallback(
    (slug) => {
      Modal.confirm({
        title: t.collaboration_reject_confirmation,
        content: t.collaboration_reject_confirmation_desc,
        okText: t.collaboration_reject_confirm,
        okType: "danger",
        cancelText: t.collaboration_reject_no,
        onOk() {
          dispatch(
            rejectCollaborator(organization.slug, initiative.slug, slug),
          );
        },
        onCancel() {},
      });
    },
    [t, organization, initiative, dispatch],
  );

  const emailCell = useCallback(
    (email, record) => (
      <React.Fragment>
        <strong>{email}</strong>
      </React.Fragment>
    ),
    [],
  );

  const nameCell = useCallback(
    (name, record) => (
      <React.Fragment>
        <Row type="flex" gutter={10}>
          <Col>
            <Avatar
              size="small"
              shape="circle"
              name={name}
              src={record.avatar}
              icon={<UserOutlined />}
            />
          </Col>
          <Col>
            <div>{name}</div>
            <Rate
              disabled={true}
              allowHalf
              value={parseScore(record.score)}
              style={{ fontSize: 22 }}
            />
            <span className="InitiativeCollaborators-scorecount">
              ({record.score_count})
            </span>
          </Col>
        </Row>
      </React.Fragment>
    ),
    [],
  );

  const requestedAtCell = useCallback(
    (requested_at, record) => (
      <React.Fragment>
        <div>{formatDateLong(requested_at, intl)}</div>
      </React.Fragment>
    ),
    [intl],
  );

  const canEditPastInitiatives = useMemo(() => {
    if (!organizationConfig) {
      return false;
    }

    return !!organizationConfig.allow_editing_past_initiatives;
  }, [organizationConfig]);

  const initiativeDone =
    !isAfterNow(initiative.end_time) && !canEditPastInitiatives;

  const statusCell = useCallback(
    (status, record) => {
      switch (status) {
        case "pending":
          return (
            <div className="InitiativeCollaborator-status-pending">
              {t.pending}
            </div>
          );
        case "rejected":
          return (
            <div className="InitiativeCollaborator-status-rejected">
              {t.rejected}
            </div>
          );
        case "accepted":
        default:
          return (
            <>
              <div className="InitiativeCollaborator-status-collaborating">
                {initiativeDone ? t.collaborated : t.collaborating}
              </div>
              <div>({formatDateLong(record.updated_at, intl)})</div>
            </>
          );
      }
    },
    [t, initiativeDone, intl],
  );

  const actionsCell = useCallback(
    (status, record) => {
      switch (status) {
        case "accepted":
          return ""; // TODO: Stop Collaborating button
        case "rejected":
          return "";
        case "pending":
        default:
          if (initiativeDone) {
            return "";
          } else {
            return (
              <React.Fragment>
                <Button
                  onClick={() => showRejectConfirm(record.slug)}
                  type="danger"
                >
                  {t.reject}
                </Button>{" "}
                <Button
                  onClick={() =>
                    dispatch(
                      acceptCollaborator(
                        organization.slug,
                        initiative.slug,
                        record.slug,
                      ),
                    )
                  }
                  type="primary"
                >
                  {t.accept}
                </Button>
              </React.Fragment>
            );
          }
      }
    },
    [t, organization, initiative, dispatch, showRejectConfirm, initiativeDone],
  );

  const data = useMemo(
    () =>
      initiative.collaborators_detail.map((c) => ({
        key: c.slug,
        name: c.name,
        slug: c.slug,
        email: (c.params || {}).email,
        avatar: c.logo_small,
        status: c.role,
        requested_at: c.created_at,
        updated_at: c.updated_at,
        participant_count: c.participant_count,
        score: c.score,
        score_count: c.score_count,
        collaboration: c,
      })),
    [initiative],
  );

  const columns = [
    {
      title: t.organization,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: nameCell,
    },
    {
      title: t.email,
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
      render: emailCell,
    },
    {
      title: t.collaboration_requested_on,
      dataIndex: "requested_at",
      key: "requested_at",
      sorter: (a, b) => new Date(a.requested_at) - new Date(b.requested_at),
      sortDirections: ["descend", "ascend"],
      render: requestedAtCell,
    },
    {
      title: t.status,
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: statusCell,
    },
    {
      title: "",
      dataIndex: "status",
      key: "actions",
      render: actionsCell,
    },
  ];

  return (
    <React.Fragment>
      <Row type="flex">
        <Col xs={24} sm={12}>
          <h2>{t.collaboration_requests_received}</h2>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={data}
        rowClassName={(_, index) =>
          index % 2 ? "Table-row-odd" : "Table-row-even"
        }
      />
    </React.Fragment>
  );
};

export default injectIntl(InitiativeCollaborators);
